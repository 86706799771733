<template>
  <div
    v-if="patient"
    class="patient-info"
  >
    <custom-scrollbar>
      <div class="patient-info-inner">
        <template v-if="isEdit">
          <div class="person-form-fields">
            <base-input
              v-model.trim="$v.patient.lastName.$model"
              :error="$v.patient.lastName.$error"
              :errors="errorsValidation.lastName"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Фамилия"
              sublabel="*"
              :required-sub="true"
              placeholder="Введите фамилию"
            />

            <base-input
              v-model.trim="$v.patient.firstName.$model"
              :error="$v.patient.firstName.$error"
              :errors="errorsValidation.firstName"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Имя"
              sublabel="*"
              :required-sub="true"
              placeholder="Введите имя"
            />

            <base-input
              v-model="patient.middleName"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Отчество"
              placeholder="Введите отчество"
            />
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  ID пациента
                </p>
                <div class="crm-input-value">
                  {{ person.id }} - {{ userCreateDate }}
                </div>
              </div>
            </div>
          </div>
          <div class="person-form-fields">
            <base-input
              v-model="$v.patient.phoneNumber.$model"
              :error="$v.patient.phoneNumber.$error"
              :errors="errorsValidation.phoneNumber"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              :disabled="isEdit"
              label="Номер тел. куда будут звонить"
              placeholder="+"
              :mask="['+', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
            />
            <!-- required -->
            <base-input
              v-model="$v.patient.email.$model"
              :error="$v.patient.email.$error"
              :errors="errorsValidation.email"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              :mask="emailMask"
              class="crm-form-field"
              label="E-mail"
            />
            <base-input
              v-model="patient.snils"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Снилс"
              :mask="[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/]"
              placeholder="XXX-XXX-XXX XX"
            />

            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  UserID
                </p>
                <div class="crm-input-value">
                  {{ person.userId }}
                </div>
              </div>
            </div>
          </div>

          <div class="person-form-fields">
            <div class="crm-form-field">
              <div class="crm-form-block relative">
                <p class="crm-label">
                  Пол <span
                    class="ml-1"
                    style="color: red"
                  >*</span>
                </p>
                <div
                  class="crm-radio-buttons"
                  :class="{ 'error': $v.patient.sex.$error }"
                >
                  <base-radio-button
                    v-model="patient.sex"
                    class="crm-radio-button"
                    :name="true"
                  >
                    Мужской
                  </base-radio-button>

                  <base-radio-button
                    v-model="patient.sex"
                    class="crm-radio-button"
                    :name="false"
                  >
                    Женский
                  </base-radio-button>
                </div>
                <div
                  v-if="$v.patient.sex.$error"
                  class="validation-error"
                >
                  <span
                    class="error-text"
                  >
                    {{ errorsValidation.sex[0] }}
                  </span>
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Дата рождения <span
                    class="ml-1"
                    style="color: red"
                  >*</span>
                </p>
                <base-date-picker
                  v-model="$v.patient.birthDate.$model"
                  :error="$v.patient.birthDate.$error"
                  :max-date="maxDate"
                />
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Возраст
                </p>
                <div class="crm-input-value">
                  {{ personAge }}
                </div>
              </div>
            </div>
            <base-input
              v-model="$v.patient.userName.$model"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="UserName (Телемед)"
              :disabled="isPersonDop"
              placeholder="+"
              :mask="['+', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /./, /./, /./, /./, /./, /./, /./, /./]"
            />
          </div>

          <div class="person-form-fields">
            <div class="crm-form-field region-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Проект
                </p>
                <div class="crm-input-value">
                  {{ projectType }}
                </div>
              </div>
            </div>

            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Статус
                </p>
                <div class="crm-input-value crm-input-value--checkbox">
                  <base-checkbox
                    v-model="patient.isVip"
                    class="crm-checkbox"
                  >
                    VIP
                  </base-checkbox>
                  <base-checkbox
                    v-model="patient.isTop"
                    class="crm-checkbox"
                  >
                    TOP
                  </base-checkbox>
                  <base-checkbox
                    v-model="patient.isTest"
                    class="crm-checkbox"
                  >
                    TEST
                  </base-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="person-form-fields">
            <base-input
              v-model="$v.patient.comment.$model"
              :input-style="{
                padding: '11px 10px 12px',
                width: '100%',
                backgroundColor: '#ffffff',
              }"
              type="textarea"
              class="crm-form-field w-100"
              label="Комментарий"
            />
          </div>

          <div class="crm-wrapper-buttons">
            <b-button
              v-if="checkFeatureAccess({
                name: 'Возможность заблокировать пациента',
                url: '/patients',
              })"
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="openDeleteModal"
            >
              Заблокировать
            </b-button>
            <b-button
              v-if="checkFeatureAccess({
                name: 'Возможность деперсонализировать пациента',
                url: '/patients',
              })"
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-3"
              @click="openDepersonalizateModal"
            >
              Деперсонализировать
            </b-button>

            <b-button
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-auto mr-3"
              @click="toggleEditMode"
            >
              Отменить
            </b-button>

            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="onClickSave"
            >
              Сохранить изменения
            </b-button>
          </div>
        </template>
        <template v-else>
          <div class="person-form-fields">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Фамилия
                </p>
                <div class="crm-input-value">
                  {{ person ? person.lastName: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Имя
                </p>
                <div class="crm-input-value">
                  {{ person ? person.firstName: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Отчество
                </p>
                <div class="crm-input-value">
                  {{ person ? person.middleName: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  ID пациента
                </p>
                <div class="crm-input-value">
                  {{ person.id }} - {{ userCreateDate }}
                </div>
              </div>
            </div>
          </div>
          <div class="person-form-fields">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Номер тел. куда будут звонить
                </p>
                <div class="crm-input-value">
                  {{ person ? person.phoneNumber: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  E-mail
                </p>
                <div class="crm-input-value">
                  {{ person ? person.email: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Снилс
                </p>
                <div class="crm-input-value">
                  {{ person ? person.snils: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  UserID
                </p>
                <div class="crm-input-value">
                  {{ person.userId }}
                </div>
              </div>
            </div>
          </div>
          <div class="person-form-fields">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Пол
                </p>
                <div class="crm-input-value">
                  {{ personSex }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Дата рождения
                </p>
                <div class="crm-input-value">
                  {{ personBirthDate }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Возраст
                </p>
                <div class="crm-input-value">
                  {{ personAge }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  UserName (Телемед)
                </p>
                <div class="crm-input-value">
                  {{ patient.userName }}
                </div>
              </div>
            </div>
          </div>
          <div class="person-form-fields">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Статус
                </p>
                <div class="crm-input-value">
                  {{ patient.isVip ? 'VIP' : '' }}
                  {{ patient.isTop ? 'TOP' : '' }}
                  {{ patient.isTest ? 'TEST' : '' }}
                  {{ !patient.isTop && !patient.isVip && !patient.isTest ? 'Не указан' : '' }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Проект
                </p>
                <div class="crm-input-value">
                  {{ projectType }}
                </div>
              </div>
            </div>
            <div class="crm-form-field" />
            <div class="crm-form-field" />
          </div>
          <div class="person-form-fields">
            <div class="crm-form-field w-100">
              <div class="crm-form-block">
                <p class="crm-label">
                  Комментарий
                </p>
                <div class="crm-input-value text-break">
                  {{ patient.comment }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="person"
            class="crm-wrapper-buttons"
          >
            <b-button
              v-if="checkFeatureAccess({
                name: 'Возможность заблокировать пациента',
                url: '/patients',
              })"
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="openDeleteModal"
            >
              Заблокировать
            </b-button>
            <b-button
              v-if="checkFeatureAccess({
                name: 'Возможность деперсонализировать пациента',
                url: '/patients',
              })"
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-3"
              @click="openDepersonalizateModal"
            >
              Деперсонализировать
            </b-button>
            <b-button
              v-if="checkFeatureAccess({
                name: 'Возможность редактировать пациента',
                url: '/patients',
              })"
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-auto mr-3"
              @click="toggleEditMode"
            >
              Редактировать
            </b-button>
          </div>
          <div
            v-if="isPersonDop"
            class="flex mt-3 gap-4"
          >
            <b-button
              v-if="checkFeatureAccess({
                name: 'Показывать кнопку Сделать Осн польз',
                url: '/patients',
              })"
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="openSetMainUserModal"
            >
              Сделать Осн польз.
            </b-button>
            <b-button
              v-if="checkFeatureAccess({
                name: 'Показывать кнопку Прикрепить к Осн',
                url: '/patients',
              })"
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="openDeleteDopModal"
            >
              Прикрепить к Осн
            </b-button>
          </div>
          <b-row
            v-if="historyPatientData?.length"
            class="mt-4"
          >
            <b-col>
              <b-form-group>
                <span
                  class="mt-2"
                  style="font-weight: 500"
                  role="button"
                  @click="isHistoryVisible = !isHistoryVisible"
                >
                  История изменений пациента
                  <b-icon
                    v-if="!isHistoryVisible"
                    icon="arrow-down-short"
                    aria-hidden="true"
                  />
                  <b-icon
                    v-else
                    icon="arrow-up-short"
                    aria-hidden="true"
                  />
                </span>
                <div
                  v-if="isHistoryVisible"
                  class="mt-4"
                >
                  <b-table
                    :bordered="true"
                    :hover="true"
                    :fields="fields"
                    small
                    :items="historyPatientData"
                  >
                    <template v-slot:cell(date)="row">
                      <span>{{ parseData(row.item.date) }}</span>
                      <span class="time-text">{{ formatTime(row.item.date) }}</span>
                    </template>
                    <template v-slot:cell(action)="row">
                      <span class="action-text">{{ row.item.action }}</span>
                    </template>
                  </b-table>
                </div>
                <div v-else />
              </b-form-group>
            </b-col>
          </b-row>
        </template>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import {
  parseISO,
  format,
  formatISO,
} from '@evd3v/date-fns';
import Bus from '@/eventBus';
import { PROJECT_TYPES } from '@/helpers/consts';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { dateWithoutTime } from '@/helpers/utils';
import { showValidationErrorMessage } from '@/helpers/messages';
import emailMask from 'text-mask-addons/dist/emailMask';
import { mixinRoles } from '@/mixins';

import {
  BaseInput,
  BaseCheckbox,
  BaseRadioButton,
  BaseDatePicker,
} from '@/components/base';

export default {
  name: 'PatientDetailPerson',
  components: {
    BaseInput,
    BaseCheckbox,
    BaseRadioButton,
    BaseDatePicker,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    person: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      PROJECT_TYPES,
      emailMask,
      isSaving: false,
      isEdit: false,
      maxDate: new Date(),
      patient: {},
      isHistoryVisible: false,
      historyPatientData: null,
      checkErrors: false,
      fields: [
        {
          key: 'date',
          label: 'Дата',
          sortable: true,
          class: 'td-date',
        },
        {
          key: 'action',
          label: 'Изменения',
          sortable: true,
        },
      ],
    };
  },
  validations() {
    return {
      patient: {
        lastName: { required },
        firstName: { required },
        birthDate: { required },
        sex: { required },
        phoneNumber: {
          required: this.isPhoneNumberRequired ? required : () => true,
        },
        comment: {
        },
        email: {
          // required,
        },
        userName: {
          // required,
        },
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.lastName = [];
      if (!this.$v.patient.lastName.required) {
        errors.lastName.push('Поле не может быть пустым');
      }

      errors.firstName = [];
      if (!this.$v.patient.firstName.required) {
        errors.firstName.push('Поле не может быть пустым');
      }

      errors.phoneNumber = [];
      if (!this.$v.patient.phoneNumber.required) {
        errors.phoneNumber.push('Поле не может быть пустым');
      }

      errors.email = [];
      if (!this.$v.patient.email.required) {
        errors.email.push('Поле не может быть пустым');
      }

      errors.birthDate = [];
      if (!this.$v.patient.birthDate.required) {
        errors.birthDate.push('Поле не может быть пустым');
      }

      errors.sex = [];
      if (!this.$v.patient.sex.required) {
        errors.sex.push('Выберите один из вариантов');
      }

      return errors;
    },
    isPhoneNumberRequired() {
      return this.checkFeatureAccess({ name: 'Номер тел. куда будут звонить - обязательное поле', url: '/patients' });
    },
    personSex() {
      if (!this.person || this.person.sex === null) return null;
      return this.person.sex === true ? 'Мужской' : 'Женский';
    },
    personBirthDate() {
      if (!this.person.birthDate) return '';
      return format(parseISO(this.person.birthDate), 'dd.MM.yyyy');
    },
    userCreateDate() {
      if (!this.person.userCreateDate) return '';
      return format(parseISO(this.person.userCreateDate), 'dd.MM.yyyy');
    },
    personAge() {
      if (!this.person || this.person.birthDate === null) return null;
      const date = new Date(this.person.birthDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const dateNow = new Date();
      const dayNow = dateNow.getDate();
      const monthNow = dateNow.getMonth() + 1;
      const yearNow = dateNow.getFullYear();
      let age = yearNow - year;

      if ((monthNow < month) || (monthNow === month && dayNow < day)) {
        age -= 1;
      }

      return age;
    },
    projectType() {
      return this.PROJECT_TYPES[this.patient.projectType]?.title || '—';
    },
    isPersonDop() {
      return !!this.person.parentId;
    },
  },
  async created() {
    await this.historyLoad();
    this.setPerson();
  },
  methods: {
    onClickClose() {
      if (!confirm('Несохраненная информация будет удалена, продолжить?')) {
        return;
      }
      this.$emit('vuedals:close');
    },
    async historyLoad() {
      const personId = this.person.id;
      try {
        const result = await this.$store.dispatch(this.$types.PATIENT_HISTORY_FETCH, personId);
        if (result.length) {
          this.historyPatientData = result?.sort((a, b) => new Date(b.date) - new Date(a.date));
        }
      } catch (err) {
        console.error(err);
      }
    },
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    formatTime(date) {
      const dateObj = new Date(date);
      dateObj.setHours(dateObj.getHours() + 3);
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();

      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      return `${hours}:${formattedMinutes}`;
    },
    async onClickSave() {
      this.$v.$touch();
      this.checkErrors = true;
      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      try {
        const formattedPatientData = {
          ...this.patient,
          userName: this.patient.userName?.replace('+', ''),
          birthDate: dateWithoutTime(formatISO(this.patient.birthDate)),
        };
        delete formattedPatientData.policies;

        if (typeof formattedPatientData.city === 'object' && formattedPatientData.city !== null) {
          formattedPatientData.city = formattedPatientData.city ? formattedPatientData.city.name : null;
        }

        formattedPatientData.phoneNumber = formattedPatientData.phoneNumber?.split(' ')
          .join('')
          .split('(')
          .join('')
          .split(')')
          .join('')
          .split('-')
          .join('');
        formattedPatientData.userPhoneNumber = formattedPatientData.userPhoneNumber?.split(' ')
          .join('')
          .split('(')
          .join('')
          .split(')')
          .join('')
          .split('-')
          .join('');

        this.isSaving = true;

        await this.$store.dispatch(this.$types.PATIENT_UPDATE, formattedPatientData);
      } catch (e) {
        console.error(e);
      } finally {
        Bus.$emit('patient:update');
        Bus.$emit('patients:update');
        this.toggleEditMode();
        this.isSaving = false;
      }
    },
    setPerson() {
      if (this.person) {
        this.patient = {
          ...this.person,
        };
        if (this.person.birthDate) {
          this.patient.birthDate = new Date(dateWithoutTime(this.person.birthDate));
        }
      }
    },
    toggleEditMode() {
      this.setPerson();
      this.isEdit = !this.isEdit;
    },
    openDeleteDopModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientDeleteDopModal',
        props: {
          patientId: this.person.id,
        },
      });
    },
    openSetMainUserModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientSetMainUserModal',
        props: {
          patientId: this.person.id,
        },
      });
    },
    openDeleteModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientDeleteModal',
        props: {
          patientId: this.person.id,
        },
      });
    },
    openDepersonalizateModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientDepersonalizateModal',
        props: {
          patientId: this.person.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.patient-info {
  background-color: #fff;
  padding: 30px 20px 30px 30px;
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  height: 100%;
  box-sizing: border-box;
}

.patient-info-inner {
  padding-right: 10px;
}

.crm-form-block {
  width: 100%;
  display: block;
}

::v-deep.crm-radio-buttons {
  position: relative;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;

  .crm-radio-button{
    flex: 1;
  }

  .crm-radio-button + .crm-radio-button {
    border-left: 1px solid #E0E0E0;
  }
  .crm-radio-text {
    padding: 0 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    text-align: center;

    &::before, &::after {
      display: none;
    }
  }
  .crm-radio-input:checked + .crm-radio-text {
    background: #6E88F3;
    color: #fff;
  }
}

.person-form-fields {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

::v-deep.crm-form-field {
  width: 270px;
  &:not(:first-child) {
    margin-left: 10px;
  }

  .crm-label {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #707070;
  }

  .base-input {
    height: 50px;
  }
}

.button_transparent {
  border: none;
}
.crm-button.width-unset {
  width: unset;
  padding-left: 40px;
  padding-right: 40px;
}

::v-deep.crm-input-value {
  background: #F9F9F9;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  min-height: 30px;
  font-size: 14px;
  line-height: 14px;

  &--checkbox {
    background: #fff;
    padding: 0;

    .custom-checkbox {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      & + .custom-checkbox {
        margin-left: 20px;
      }
    }
  }
}
.region-field{
  width: 24%;
}

.action-text{
  word-break: break-all;
}

::v-deep label {
  margin-bottom: 0;
}
.relative{
  position: relative;
}
.error {
  margin-left: 1px;
  outline: 1px solid red;
}
.validation-error{
  position: absolute;
  top: 100%;
  left: 0;
}
.error-text{
  font-size: 12px;
  color: red;
}
::v-deep.table .td-date {
  width: 140px;
  .time-text{
    margin-left: 7px;
  }
}
</style>
