<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="region?.id ? 'Редактирование региона' : 'Добавление региона'"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      class="region-form"
    >
      <b-form-group
        label="Название региона"
        label-class="required"
        :state="!$v.region.name.$error"
        :invalid-feedback="errorsValidation.name[0]"
      >
        <b-form-input
          v-model.trim="$v.region.name.$model"
          type="text"
          placeholder="Введите название региона"
        />
      </b-form-group>

      <b-form-group
        label="Код региона"
        label-class="required"
        :state="!$v.region.code.$error"
        :invalid-feedback="errorsValidation.code[0]"
      >
        <b-form-input
          v-model.trim="$v.region.code.$model"
          type="text"
          placeholder="Введите код региона"
        />
      </b-form-group>

      <b-form-group
        label="Конституционный код"
        label-class="required"
        :state="!$v.region.constitutionCode.$error"
        :invalid-feedback="errorsValidation.constitutionCode[0]"
      >
        <b-form-input
          v-model.trim="$v.region.constitutionCode.$model"
          type="text"
          placeholder="Введите конституционный код"
        />
      </b-form-group>

      <b-form-group
        label="Таймзона"
        label-class="required"
        :state="!$v.region.timezone.$error"
        :invalid-feedback="errorsValidation.timezone[0]"
      >
        <b-form-input
          v-model.trim="$v.region.timezone.$model"
          type="text"
          placeholder="Введите таймзону"
        />
      </b-form-group>

      <b-form-group
        label="ОКАТО"
        label-class="required"
        :state="!$v.region.okatoCode.$error"
        :invalid-feedback="errorsValidation.okatoCode[0]"
      >
        <b-form-input
          v-model.trim="$v.region.okatoCode.$model"
          type="text"
          placeholder="Введите ОКАТО"
        />
      </b-form-group>

      <div class="d-flex">
        <b-form-checkbox
          v-model="region.isTmk"
        >
          ТМК
        </b-form-checkbox>

        <b-form-checkbox
          v-model="region.isDismonitoring"
          class="ml-4"
        >
          Дисмониторинг
        </b-form-checkbox>
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';

import { locationService } from '@/services';
import {
  showValidationErrorMessage,
  showSuccessCustomMessage,
  showErrorCustomMessage,
} from '@/helpers/messages';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'RegionEditModal',
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    regionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isSaving: false,
      isOpen: true,
      region: {
        code: null,
        constitutionCode: null,
        isDismonitoring: false,
        isTmk: false,
        name: null,
        okatoCode: null,
        timezone: null,
        isActive: true,
      },
    };
  },
  validations() {
    return {
      region: {
        name: {
          required,
        },
        code: {
          required,
        },
        constitutionCode: {
          required,
        },
        timezone: {
          required,
        },
        okatoCode: {
          required,
        },
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.name = [];
      if (!this.$v.region.name.required) {
        errors.name.push('Поле не может быть пустым');
      }

      errors.code = [];
      if (!this.$v.region.code.required) {
        errors.code.push('Поле не может быть пустым');
      }

      errors.constitutionCode = [];
      if (!this.$v.region.constitutionCode.required) {
        errors.constitutionCode.push('Поле не может быть пустым');
      }

      errors.timezone = [];
      if (!this.$v.region.timezone.required) {
        errors.timezone.push('Поле не может быть пустым');
      }

      errors.okatoCode = [];
      if (!this.$v.region.okatoCode.required) {
        errors.okatoCode.push('Поле не может быть пустым');
      }

      return errors;
    },
  },
  created() {
    if (this.regionData) {
      const region = JSON.parse(JSON.stringify(this.regionData));
      this.region = { ...region };
    }
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClickSave() {
      this.$v.$touch();
      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      this.isSaving = true;

      try {
        if (this.region.id) {
          // ! check it
          await this.regionHandler(this.region, 'update');
        } else {
          await this.regionHandler(this.region, 'create');
        }

        Bus.$emit('regions:update');
        this.onClose();
      } finally {
        this.isSaving = false;
      }
    },
    async regionHandler(obj, value) {
      try {
        if (value === 'create') {
          const data = await locationService.createRegion(obj);
          showSuccessCustomMessage('Регион успешно создан!');
          return data;
        }
        if (value === 'update') {
          const data = locationService.updateRegion(obj);
          showSuccessCustomMessage('Регион успешно изменен!');
          return data;
        }
      } catch (err) {
        if (value === 'create') {
          console.warn(err);
          showErrorCustomMessage('Не удалось создать регион');
          throw new Error(err);
        }
        console.warn(err);
        showErrorCustomMessage('Не удалось изменить регион');
        throw new Error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.region-id {
  font-size: 14px;
  line-height: 14px;
  color: #908F8D;
  margin-bottom: 15px;
}
.region-form {
  width: 100%;

  .crm-form-field + .crm-form-field {
    margin-top: 15px;
  }
}
.crm-wrapper-buttons {
  margin-top: 50px;
}

::v-deep .validation-errors {
  top: calc(100% + -1.5px);
}
::placeholder {
  font-size: 0.8em;
}
</style>
