<template>
  <div class="crm-tab-panel">
    <b-container>
      <div class="crm-tab-list-wrapper">
        <custom-scrollbar :ops="ops">
          <div class="crm-tab-list">
            <div
              v-for="({id, title, type, hideCloseButton, data }) in tabs"
              :key="id"
              class="crm-tab-item"
            >
              <div class="crm-tab-item-title">
                {{ title }}
              </div>

              <icon-minimize
                class="crm-icon-minimize"
                width="20px"
                height="20px"
                @click="onOpenFunc(id, type, data)"
              />
              <icon-cross
                v-if="!hideCloseButton"
                class="crm-icon-cross"
                width="20px"
                height="20px"
                viewBox="0 0 16 16"
                @click="removeTab(id)"
              />
            </div>
          </div>
        </custom-scrollbar>
      </div>
    </b-container>
  </div>
</template>

<script>
import IconCross from 'assets/images/clear_icon.svg';
import IconMinimize from 'assets/images/minimize.svg';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'TabPanel',
  components: {
    IconCross,
    IconMinimize,
  },
  data() {
    return ({
      ops: {
        vuescroll: {
          mode: 'native',
          checkShifKey: true,
        },
        scrollPanel: {
          scrollingX: true,
          scrollingY: false,
        },
        rail: {
          background: '#D5D9E5',
          gutterOfSide: '1.5px',
          gutterOfEnds: '10px',
          opacity: 0.4,
        },
        bar: {
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: '#ADB0B8',
          minSize: 0.15,
        },
      },
      tabTypes: {
        0: 'appeal',
      },
    });
  },
  computed: {
    tabs() {
      return this.$store.state.TabPanel.tabs;
    },
  },
  watch: {
    tabs: {
      handler() {
        this.$store.commit(this.$types.TAB_PANEL_ITEMS_SAVE_TO_LOCALSTORAGE);
      },
      deep: true,
    },
  },
  created() {
    this.$store.commit(this.$types.TAB_PANEL_ITEMS_FETCH_FROM_LOCALSTORAGE);
  },
  methods: {
    removeTab(id) {
      this.$store.commit(this.$types.TAB_PANEL_ITEMS_FETCH_FROM_LOCALSTORAGE);
      this.$store.commit(this.$types.TAB_PANEL_ITEM_REMOVE_BY_ID, id);
    },
    onOpenFunc(panelTabId, type, data) {
      if (type === 0) {
        this.$store.commit(this.$types.OPEN_MODAL, {
          name: 'AppealEditorModal',
          props: {
            panelTabId,
          },
        });
      }
      if (type === 1) {
        this.$store.commit(this.$types.OPEN_MODAL, {
          name: 'IndemnityModal',
          props: {
            panelTabId,
          },
        });
      }
      if (type === 2) {
        const { appealId, personId, ...panelTabAppealData } = data;
        uiService.showModal(MODALS.APPEAL_EDITOR_NEW_MODAL, {
          name: 'AppealEditorModalNew',
          props: {
            title: appealId ? `Редактирование обращения - ${appealId}` : 'Создание обращения',
            appealId,
            panelTabId,
            personId,
            panelTabAppealData,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-tab-panel {
  position: fixed;
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
}

::v-deep.crm-tab-list-wrapper {
  width: 100%;

  .hasHBar .__panel {
    // overflow: auto!important;
    margin-bottom: -17px;
  }
}

.crm-tab-list {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0 0;
}

::v-deep.crm-tab-item {
  width: 210px;
  background-color: #fff;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  box-shadow: 0px -1px 4px rgba(50, 50, 50, 0.2);
  border-radius: 4px 4px 0px 0px;

  & + .crm-tab-item {
    margin-left: 10px;
  }
}

.crm-tab-item-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
}

::v-deep.crm-icon-cross {
  cursor: pointer;
  flex-shrink: 0;

  circle {
    fill: transparent;
    transition: all .3s;
  }

  path {
    fill: $blue;
  }

  &:hover {
    circle {
      fill: $blue-light;
    }
  }
}

.crm-icon-minimize {
  cursor: pointer;
  margin-right: 5px;
}

</style>
