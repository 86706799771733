export const APP_URL = process.env.VUE_APP_URL;

// eslint-disable-next-line
export let APP_HOST = process.env.VUE_APP_HOST;
export const APP_HOST_INTERNAL = process.env.VUE_APP_HOST_INTERNAL;
export const APP_DOCTIS = process.env.VUE_APP_DOCTIS;
export const APP_DOCTIS_TOKEN = process.env.VUE_APP_DOCTIS_TOKEN;
export const APP_DOCTIS_API_KEY = process.env.VUE_APP_DOCTIS_API_KEY;
export const APP_DOCTIS_SERVICE_URL = process.env.VUE_APP_DOCTIS_SERVICE_URL;
export const APP_DOCTIS_SERVICE_KEY = process.env.VUE_APP_DOCTIS_SERVICE_KEY;
export const APP_DOCTIS_FILE_SERVER_URL = process.env.VUE_APP_DOCTIS_FILE_SERVER_URL;
export const APP_PATIENT_URL = process.env.VUE_APP_PATIENT_URL;
export const APP_DOCTOR_URL = process.env.VUE_APP_DOCTOR_URL;
export const APP_ANALYSIS_API_URL = process.env.VUE_APP_ANALYSIS_API_URL;
export const APP_ANALYSIS_API_TOKEN = process.env.VUE_APP_ANALYSIS_API_TOKEN;
export const APP_SETTINGS = process.env.VUE_APP_SETTINGS;
export const APP_SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
export const APP_YANDEX_MAP_API_KEY = process.env.VUE_APP_YANDEX_MAP_API_KEY;
// eslint-disable-next-line
export let APP_HUB_SOCKET_URL = process.env.VUE_APP_HUB_SOCKET_URL;
export const APP_HUB_SOCKET_URL_INTERNAL = process.env.VUE_APP_HUB_SOCKET_URL_INTERNAL;

export const setInternalHost = () => {
  APP_HOST = APP_HOST_INTERNAL;
};

export const setInternalSocket = () => {
  APP_HUB_SOCKET_URL = APP_HUB_SOCKET_URL_INTERNAL;
};

if (localStorage.getItem('internal')) {
  setInternalHost();
  setInternalSocket();
}

export default {
  APP_HOST,
  APP_URL,
  APP_DOCTIS,
  APP_DOCTIS_TOKEN,
  APP_DOCTIS_API_KEY,
  APP_DOCTIS_SERVICE_URL,
  APP_DOCTIS_SERVICE_KEY,
  APP_PATIENT_URL,
  APP_DOCTOR_URL,
  APP_ANALYSIS_API_URL,
  APP_ANALYSIS_API_TOKEN,
  APP_SETTINGS,
  APP_SENTRY_DSN,
  APP_YANDEX_MAP_API_KEY,
  APP_HUB_SOCKET_URL,

  setInternalHost,
  setInternalSocket,
};
