<template>
  <div class="appeal-detail">
    <custom-scrollbar>
      <div class="crm-inner">
        <div class="crm-title-row mb-4">
          <div class="crm-title mb-0">
            Информация об обращении
          </div>
          <div class="d-flex align-items-center">
            <b-button
              v-if="checkFeature(FEATURES_FOR_PATIENTS.detailInfoChatIcon) && appeal.chatAppealId"
              v-b-tooltip.hover.bottomright.v-light
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="chat-btn-modal mr-4"
              title="Чат. История обращения"
              @click="openPatientAppealChatModal"
            >
              <b-icon
                icon="chat-dots"
              />
            </b-button>

            <b-button
              v-if="appeal.hasIndemnityLetters && checkFeature(FEATURES_FOR_PATIENTS.detailInfoHistoryIndemnity)"
              :type="$const.SECONDARY_BUTTON"
              variant="outline-primary"
              size="sm"
              @click="openIndemnityHistoryModal"
            >
              История ГП
            </b-button>

            <icon-message
              class="icon-message crm-icon crm-border"
              @click="openSmsSendModal"
            />
            <a
              :href="`tel:${appeal.person.phoneNumber}`"
              class="crm-icon crm-border call-link"
            >
              <b-icon
                icon="telephone"
                height="20"
                width="20"
                style="fill: rgb(75, 98, 193)"
              />
            </a>
            <icon-history
              class="icon-history crm-icon"
              @click="openAppealHistoryModal"
            />
            <icon-edit
              class="edit-icon crm-icon"
              @click="openAppealEditorModal"
            />
            <b-button
              v-if="checkFeature(FEATURES_FOR_PATIENTS.detailInfoToChat) && appeal.chatAppealId"
              v-b-tooltip.hover.bottomleft.v-light
              class="chat-btn"
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              title="Чат. История всех обращений"
              @click="openPatientDetailChat"
            >
              В Чат
            </b-button>
          </div>
        </div>

        <div class="crm-row">
          <div class="crm-column">
            <div class="crm-subtitle">
              Статус
            </div>
            <div
              :style="{color: statusColor}"
              class="crm-value"
            >
              {{ statusText }}
            </div>
          </div>

          <div class="crm-column">
            <div class="crm-subtitle">
              ID обращения
            </div>
            <div class="crm-value">
              {{ appeal.id }}
            </div>
          </div>

          <div class="crm-column">
            <div class="crm-subtitle">
              Дата и время
            </div>
            <div class="crm-value">
              {{ createDate }}
            </div>
          </div>

          <div class="crm-column">
            <div class="crm-subtitle">
              Дата закрытия
            </div>
            <div class="crm-value">
              {{ closeDate }}
            </div>
          </div>

          <div
            v-if="appeal.urgency || selectedPurposeServiceCoordination"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Срочность
            </div>
            <div
              class="crm-value"
              :class="appeal.urgency && 'red'"
            >
              {{ appeal.urgency ? 'Срочно' : 'Не срочно' }}
            </div>
          </div>
          <div
            v-if="appeal.transportation || selectedPurposeServiceCoordination"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Нужна ли транспортировка
            </div>
            <div
              class="crm-value"
              :class="appeal.transportation && 'red'"
            >
              {{ appeal.transportation ? 'Нужна' : 'Не нужна' }}
            </div>
          </div>
        </div>

        <div
          v-if="cityName || clinicName"
          class="crm-row"
        >
          <div
            class="crm-column"
          >
            <div class="crm-subtitle">
              Город
            </div>
            <div class="crm-value">
              {{ cityName ? cityName : '-' }}
            </div>
          </div>
          <div
            class="crm-column"
          >
            <div class="crm-subtitle">
              Клиника
            </div>
            <div class="crm-value">
              {{ clinicName ? clinicName : '-' }}
            </div>
          </div>
        </div>

        <div class="crm-row">
          <div class="crm-column">
            <div class="crm-subtitle">
              Цель обращения
            </div>
            <div class="crm-value">
              {{ purposeTitle }}
            </div>
          </div>

          <div
            v-if="appeal.category"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Категория обращения
            </div>
            <div class="crm-value">
              {{ appeal.category.name }}
            </div>
          </div>

          <div
            v-if="appeal.categoryAnotherText"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Другая категория обращения
            </div>
            <div class="crm-value">
              {{ appeal.categoryAnotherText }}
            </div>
          </div>

          <div
            v-if="appeal.type"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Тип обращения
            </div>
            <div class="crm-value">
              {{ typeTitle }}
            </div>
          </div>

          <div
            v-if="appeal.typeAnotherText"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Другой тип обращения
            </div>
            <div class="crm-value">
              {{ appeal.typeAnotherText }}
            </div>
          </div>

          <div
            v-if="appeal.gostelemedType"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Гостелемед
            </div>
            <div class="crm-value">
              {{ appeal.gostelemedType.title }}
            </div>
          </div>

          <div
            v-if="appeal.rejectionReason"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Причина отказа
            </div>
            <div class="crm-value">
              {{ appeal.rejectionReason }}
            </div>
          </div>

          <div
            v-if="appeal.checkupProfile"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Профиль чекапа
            </div>
            <div
              v-if="appeal.checkupProfile"
              class="crm-value"
            >
              {{ appeal.checkupProfile.title }}
            </div>
            <div
              v-else
              class="crm-value"
            >
              {{ '---' }}
            </div>
          </div>
          <div
            v-if="anotherCheckupProfile"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Другой профиль для чекапа
            </div>
            <div
              class="crm-value"
            >
              {{ anotherCheckupProfile }}
            </div>
          </div>
          <div
            v-if="checkupDate"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Дата чекапа
            </div>
            <div
              class="crm-value"
            >
              {{ checkupDate }}
            </div>
          </div>
        </div>

        <div
          v-if="appeal.selectedPolicy || appeal.policy"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Полис
            </div>
            <div class="crm-value">
              <PatientDetailPolicy
                :policy="appeal.selectedPolicy || appeal.policy"
                :person-id="appeal.person.id"
                :show-new-indemnity-button="false"
              />
            </div>
          </div>
        </div>

        <div
          v-if="policyNumber"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Номер полиса
            </div>
            <div class="crm-value">
              {{ policyNumber }}
            </div>
          </div>
        </div>
        <div
          v-if="administratorName"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              ФИО Администратора
            </div>
            <div class="crm-value">
              {{ administratorName }}
            </div>
          </div>
        </div>
        <div
          v-if="policyBuyDate"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Примерная дата приобретения полиса
            </div>
            <div class="crm-value">
              {{ policyBuyDate }}
            </div>
          </div>
        </div>
        <div
          v-if="selectedCategoryNoClient"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Полис активирован на сайте страховой компании
            </div>
            <div class="crm-value">
              {{ appeal.isActivePolicy ? 'Да' : 'Нет' }}
            </div>
          </div>
        </div>
        <div
          v-if="insuranseCompany"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Страховая компания
            </div>
            <div class="crm-value">
              {{ insuranseCompany ? insuranseCompany.name : '-' }}
            </div>
          </div>
        </div>
        <div
          v-if="operatorComment"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Комментарий оператора
            </div>
            <div class="crm-value pre-line">
              {{ operatorComment }}
            </div>
          </div>
        </div>
        <div
          v-if="reconciliationCommentForOperator"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Комментарий для оператора
            </div>
            <div class="crm-value pre-line">
              {{ reconciliationCommentForOperator }}
            </div>
          </div>
        </div>
        <div
          v-if="yandexLink"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Ссылка на яндекс трекер
            </div>
            <div class="crm-value">
              {{ yandexLink }}
            </div>
          </div>
        </div>
        <div
          v-if="regionName"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Регион
            </div>
            <div class="crm-value">
              {{ regionName }}
            </div>
          </div>
        </div>
        <div
          v-if="orderNumber"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Номер заказа
            </div>
            <div class="crm-value">
              {{ orderNumber }}
            </div>
          </div>
        </div>
        <div
          v-if="consultationId"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Id консультации
            </div>
            <div class="crm-value">
              {{ consultationId }}
            </div>
          </div>
        </div>
        <div class="crm-row crm-row-1">
          <div class="crm-column">
            <div class="crm-subtitle">
              Комментарий
            </div>
            <div class="crm-value pre-line">
              {{ appeal.comment }}
            </div>
          </div>
        </div>
        <div
          v-if="whereRrequestWasSentTo"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Куда передано обращение
            </div>
            <div class="crm-value pre-line">
              {{ whereRrequestWasSentTo }}
            </div>
          </div>
        </div>

        <div class="d-flex my-4">
          <div
            v-if="appeal.responsible"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Ответственный
            </div>
            <div class="crm-value">
              {{ appeal.responsible.displayName }}
            </div>
          </div>
          <div
            v-if="appeal.responsibleOperator"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Оператор ответственный за обращение
            </div>
            <div class="crm-value">
              {{ appeal.responsibleOperator.displayName }}
            </div>
          </div>
        </div>

        <div
          v-if="appeal.fileIds?.length"
          class="crm-row crm-row-1"
        >
          <div
            class="crm-column"
          >
            <div class="crm-subtitle">
              Файлы
            </div>
            <div class="crm-value">
              <div
                v-for="(file) in getComputedFiles(appeal.fileIds)"
                :key="file.id"
                class="appeal-file"
              >
                <base-file
                  :file="file"
                  class="cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="appeal.files?.length"
          class="crm-row crm-row-1"
        >
          <div
            class="crm-column"
          >
            <div class="crm-subtitle">
              Файлы
            </div>
            <div class="crm-value">
              <div
                v-for="file in getComputedFiles(appeal.files)"
                :key="file.id"
                class="appeal-file"
              >
                <base-file
                  :file="file"
                  class="cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-for="reconciliation in appeal.reconciliations"
          :key="reconciliation.id"
          class="crm-row crm-row-1"
        >
          <base-dropdown
            :open="false"
            header="Информация о согласовании"
          >
            <div class="crm-row">
              <div class="crm-column">
                <div class="crm-subtitle">
                  Статус
                </div>
                <div
                  class="crm-value"
                  :class="getStatusColor(reconciliation.status)"
                >
                  {{ getStatusText(reconciliation.status) }}
                </div>
              </div>

              <div class="crm-column">
                <div class="crm-subtitle">
                  ID согласования
                </div>
                <div class="crm-value">
                  {{ reconciliation.id }}
                </div>
              </div>

              <div class="crm-column">
                <div class="crm-subtitle">
                  Дата и время
                </div>
                <div class="crm-value">
                  {{ getDate(reconciliation.createDate) }}
                </div>
              </div>

              <div class="crm-column">
                <div class="crm-subtitle">
                  ID связанного обращения
                </div>
                <div class="crm-value">
                  {{ reconciliation.appealId }}
                </div>
              </div>
            </div>

            <div class="crm-row crm-row-2">
              <div class="crm-column">
                <div class="crm-subtitle">
                  Согласованные услуги
                </div>
                <div class="crm-value">
                  {{ reconciliation.agreedServices }}
                </div>
              </div>

              <div class="crm-column">
                <div class="crm-subtitle">
                  Название клиники
                </div>
                <div class="crm-value">
                  {{ reconciliation.clinic.name }}
                </div>
              </div>
            </div>

            <div class="crm-row crm-row-2">
              <div class="crm-column">
                <div class="crm-subtitle">
                  Несогласованные услуги
                </div>
                <div class="crm-value">
                  {{ reconciliation.notAgreedServices }}
                </div>
              </div>

              <div class="crm-column">
                <div class="crm-subtitle">
                  Причина отказа
                </div>
                <div class="crm-value">
                  {{ reconciliation.regectionReason }}
                </div>
              </div>
            </div>

            <div
              v-if="reconciliation.diagnosis"
              class="crm-row crm-row-2"
            >
              <div
                class="crm-column"
              >
                <div class="crm-subtitle">
                  Диагноз
                </div>
                <div class="crm-value">
                  {{ reconciliation.diagnosis }}
                </div>
              </div>
              <div
                v-if="reconciliation.doctorName && allowedEditDoctorFullName"
                class="crm-column"
              >
                <div class="crm-subtitle">
                  ФИО Врача
                </div>
                <div class="crm-value">
                  {{ reconciliation.doctorName }}
                </div>
              </div>
            </div>

            <div class="crm-row crm-row-1">
              <div class="crm-column">
                <div class="crm-subtitle">
                  Комментарий врача-куратора
                </div>
                <div class="crm-value pre-line">
                  {{ reconciliation.doctorComment }}
                </div>
              </div>
            </div>

            <div
              v-if="operatorComment"
              class="crm-row crm-row-1"
            >
              <div class="crm-column">
                <div class="crm-subtitle pre-line">
                  Комментарий для оператора
                </div>
                <div class="crm-value">
                  {{ operatorComment }}
                </div>
              </div>
            </div>

            <div
              v-if="reconciliation.responsible"
              class="crm-row crm-row-1"
            >
              <div class="crm-column">
                <div class="crm-subtitle">
                  Ответственный за согласование
                </div>
                <div class="crm-value">
                  {{ reconciliation.responsible.displayName }}
                </div>
              </div>
            </div>

            <div
              v-if="reconciliation.files.length"
              class="crm-row crm-row-1"
            >
              <div
                class="crm-column"
              >
                <div class="crm-subtitle">
                  Файлы
                </div>
                <div class="crm-value">
                  <div
                    v-for="(file) in getComputedFiles(reconciliation.files)"
                    :key="file.id"
                    class="appeal-file"
                  >
                    <base-file
                      :file="file"
                      class="cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
          </base-dropdown>
        </div>

        <div
          v-if="reconciliationComment"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Комментарий ответственного
            </div>
            <div class="crm-value pre-line">
              {{ reconciliationComment }}
            </div>
          </div>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { parseISO, format } from '@evd3v/date-fns';
import { mixinRoles } from '@/mixins';
import { FEATURES_FOR_PATIENTS } from '@/helpers/consts';
import {
  APPEALS_PURPOSES_TEXTS,
  APPEALS_QUALITY_CONTROL_WHERE_TO_SEND_TEXTS,
  APPEAL_RECONCILIATION_STATUSES_TEXTS,
  APPEALS_STATUSES_TEXTS,
} from '@/services/appeals/appeals.const';
import Bus from '@/eventBus';
import { utc0ToDateWithUserTimezone } from '@/helpers/utils';

import {
  BaseFile,
  BaseDropdown,
} from '@/components/base';

import PatientDetailPolicy from '@/components/Patients/PatientDetailPolicy';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

import IconMessage from 'assets/images/message.svg';
import IconHistory from 'assets/images/icon_history.svg';
import IconEdit from 'assets/images/edit_icon.svg';

export default {
  name: 'PatientDetailAppeal',
  components: {
    BaseDropdown,
    BaseFile,
    PatientDetailPolicy,
    IconHistory,
    IconEdit,
    IconMessage,
  },
  mixins: [mixinRoles],
  props: {
    appeal: {
      type: Object,
      default: () => ({}),
    },
    patient: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      statuses: {
        0: 'В работе',
        1: 'Отложено',
        2: 'Готово',
      },
      statusesAppeal: {
        0: 'В работе',
        1: 'Отложено',
        2: 'Закрыто',
      },
      purposes: {
        0: 'Информационное',
        1: 'Чекап',
        2: 'Лечение / плановая госпитализация',
        3: 'Экстренная госпитализация',
      },
      APPEALS_QUALITY_CONTROL_WHERE_TO_SEND_TEXTS,
      FEATURES_FOR_PATIENTS,
    };
  },
  computed: {
    allowedEditDoctorFullName() {
      return this.checkFeatureAccess({ name: 'ФИО врача в согл. в. инф. о пациенте', url: '/patients' });
    },
    createDate() {
      const { dateTime } = this.appeal;
      return this.getDate(dateTime);
    },
    closeDate() {
      const { closeDate } = this.appeal;
      return closeDate !== null ? this.getDate(closeDate) : 'отсутствует';
    },
    whereRrequestWasSentTo() {
      const { qualityControlPurposeType } = this.appeal;
      if (qualityControlPurposeType === null) return;
      return APPEALS_QUALITY_CONTROL_WHERE_TO_SEND_TEXTS.find((item) => item.id === qualityControlPurposeType)?.title;
    },
    reconciliations() {
      return this.appeal.reconciliations;
    },
    status() {
      return APPEALS_STATUSES_TEXTS.find((item) => item.id === this.appeal.status);
    },
    statusColor() {
      return this.status?.color;
    },
    statusText() {
      return this.status?.title;
    },
    reconciliationCommentForOperator() {
      if (!this.reconciliations.length) return;
      return this.appeal.reconciliations[0]?.commentForOperator;
    },
    typeTitle() {
      if (this.appeal?.type?.title) {
        return this.appeal.type.title || '';
      }
      return this.appeal.type?.name || '';
    },
    purposeTitle() {
      if (this.appeal.isOldAppeal) {
        return this.purposes[this.appeal.oldPurpose];
      }
      return APPEALS_PURPOSES_TEXTS.find((item) => item.id === this.appeal.purpose)?.title;
    },
    categoryName() {
      return this.appeal.category?.name || '–';
    },
    selectedPurposeServiceCoordination() {
      return this.purposeTitle === 'Согласование услуг';
    },
    selectedPurposeContractDepartament() {
      return this.purposeTitle === 'Договорной отдел';
    },
    anotherCheckupProfile() {
      if (this.appeal.checkupProfile && this.appeal.checkupProfile.title === 'Другое') {
        return this.appeal.checkupAnotherText;
      }

      return false;
    },
    policyNumber() {
      return this.appeal.policyNumber;
    },
    administratorName() {
      return this.appeal.administratorName;
    },
    policyBuyDate() {
      if (this.appeal.policyBuyDate) {
        return format(parseISO(this.appeal.policyBuyDate), 'dd.MM.yyyy');
      }

      return false;
    },
    insuranseCompany() {
      return this.appeal.insuranseCompany;
    },
    operatorComment() {
      return this.appeal.operatorComment;
    },
    cityName() {
      if (this.appeal.isOldAppeal) {
        return this.appeal.clinic?.address;
      }

      return this.appeal.city?.name;
    },
    clinicName() {
      if (this.appeal.isOldAppeal) {
        return this.appeal.clinic?.title;
      }

      return this.appeal.clinic?.name;
    },
    yandexLink() {
      return this.appeal.yandexLink;
    },
    regionName() {
      return this.appeal.region?.name;
    },
    orderNumber() {
      return this.appeal.orderNumber;
    },
    checkupDate() {
      if (this.appeal.checkupDate) {
        return format(parseISO(this.appeal.checkupDate), 'dd.MM.yyyy');
      }

      return false;
    },
    consultationId() {
      return this.appeal.consultationId;
    },
    reconciliationComment() {
      return this.appeal.reconciliationComment;
    },
    selectedCategoryNoClient() {
      return this.categoryName === 'Нет клиента в СРМ';
    },
  },
  methods: {
    checkFeature(key) {
      return this.checkFeatureAccess({ name: key?.name, url: key?.url });
    },
    getComputedFiles(fileIds) {
      return fileIds.map((file) => ({
        guid: file.id,
        title: file.name,
      }));
    },
    getDate(dateTime) {
      return format(utc0ToDateWithUserTimezone(dateTime), 'dd.MM.yyyy HH:mm');
    },
    getStatusColor(status) {
      const isGreen = [1, 2, 3].includes(status);

      if (status === 0) return 'red';
      if (isGreen) return 'green';
      if (status === 4) return 'yellow';

      return '';
    },
    getStatusColorAppeal() {
      if (this.appeal.status === 0) return 'green';
      if (this.appeal.status === 1) return 'yellow';
      if (this.appeal.status === 2) return 'red';

      return '';
    },
    getStatusText(status, isAppeal) {
      const statuses = isAppeal ? APPEALS_STATUSES_TEXTS : APPEAL_RECONCILIATION_STATUSES_TEXTS;
      return statuses.find((item) => item.id === status)?.title;
    },
    openPatientAppealChatModal() {
      uiService.showModal(MODALS.APPEAL_PATIENT_CHAT_MODAL, {
        name: 'PatientAppealChatModal',
        props: {
          patient: this.patient,
          appeal: this.appeal,
        },
      });
    },
    openPatientDetailChat() {
      this.$emit('open-chat-modal', this.appeal);
    },
    openSmsSendModal() {
      const { firstName, middleName } = this.appeal.person;
      const middleNameString = middleName ? ` ${middleName}` : '';
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'SmsSendModal',
        props: {
          numberProp: this.appeal.person.phoneNumber,
          name: `${firstName || ''}${middleNameString}`,
        },
      });
    },
    openAppealHistoryModal() {
      uiService.showModal(MODALS.APPEAL_HISTORY_MODAL, {
        name: 'AppealHistoryModal',
        props: {
          appealId: this.appeal.id,
        },
      });
    },
    openIndemnityHistoryModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'IndemnityHistoryModal',
        props: {
          personId: this.appeal.person.id,
        },
      });
    },
    openAppealEditorModal() {
      if (this.appeal.isOldAppeal) {
        this.$store.commit(this.$types.OPEN_MODAL, {
          name: 'AppealEditorModal',
          props: {
            afterSaveFunc: () => Bus.$emit('patient-detail-appeals:get-appeals'),
            appealId: this.appeal.id,
            patientId: this.patient.id,
          },
        });

        return;
      }
      uiService.showModal(MODALS.APPEAL_EDITOR_NEW_MODAL, {
        name: 'AppealEditorModalNew',
        props: {
          title: `Редактирование обращения - ${this.appeal.id}`,
          appealId: this.appeal.id,
          personId: this.patient.id,
          isEditAppeal: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.appeal-detail {
  box-sizing: border-box;
  height: 100%;
  flex: 1;
  width: calc(100% - 280px);
}

.crm-inner {
  height: 100%;
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
}

.crm-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 30px;
}
.chat-btn {
  width: 45px;
  height: 30px;
  border: none;
  outline: 1px solid #E0E0E0;
  border-radius: 5px;
  flex-shrink: 0;
  padding: inherit;
  margin-left: 10px;
  &:hover {
    background: #ffffff;
    color: rgb(12, 12, 230);
    opacity: 0.5;
  }
}
.chat-btn-modal{
  background: #ffffff;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  &:hover {
    background: #ffffff;
    color: rgb(12, 12, 230);
    opacity: 0.5;
  }
}

.crm-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, calc((100% - 60px)/4));
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  width: 100%;

  &-1 {
    grid-template-columns: repeat(auto-fit, 100%);
  }

  &-2 {
    grid-template-columns: repeat(auto-fit, calc((100% - 20px)/2));
  }

  & + & {
    margin-top: 30px;
  }
}

.crm-column {
  flex: 1;
  max-width: 100%;
  word-break: break-word;
}

.crm-value {
  margin-top: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  width: 100%;

  &.green {
    color: $green;
  }
  &.yellow {
    color: #F2994A;
  }
  &.red {
    color: $red;
  }
}

.appeal-file {
  margin-top: 10px;
}

::v-deep.base-dropdown-wrapper {
  margin-bottom: 0;
}

.crm-title-row {
  display: flex;
  justify-content: space-between;
}

.crm-icon {
  margin-left: 10px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    opacity: 0.5;
  }

  &.crm-border {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
  }
}

.edit-icon {
  path {
    fill: $blue;
  }
}

.call-link {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer{
  cursor: pointer;
}

</style>
