import NProgress from 'nprogress/nprogress';
import { format } from '@evd3v/date-fns';
import axios from 'axios';

import * as types from '@/store/types';
import { removeNamespaces, showMessage, headers } from '@/helpers/utils';
import { api } from '@/helpers/api';
import { APP_DOCTIS, APP_DOCTIS_API_KEY } from '@/app.config';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.SCHEDULE_FETCH]({ commit }, { id, date }) {
    NProgress.start();

    try {
      commit(localTypes.SCHEDULE_LOADING_SET, true);

      const params = new URLSearchParams();

      params.append('date', date);
      params.append('timeZone', new Date().getTimezoneOffset() / -60);

      const { data: { schedule, liveSchedule } } = await api.get(`Schedule/GetByDoctor/${id}`, { params });
      if (schedule) {
        const modifiedSchedule = schedule.map((item) => ({
          ...item,
          formattedTime: format(new Date(item.unixTime * 1000), 'HH:mm'),
        }));
        const modifiedLiveSchedule = liveSchedule.map((item) => ({
          ...item,
          formattedTime: format(new Date(item.slot * 1000), 'HH:mm'),
        }));
        commit(localTypes.SCHEDULE_SET, { schedule: modifiedSchedule, liveSchedule: modifiedLiveSchedule });
      } else {
        commit(localTypes.SCHEDULE_SET, { schedule: [], liveSchedule: [] });
      }
    } catch (e) {
      commit(localTypes.SCHEDULE_SET, { schedule: [], liveSchedule: [] });

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить расписание',
      });

      console.warn(e);

      throw e;
    } finally {
      commit(localTypes.SCHEDULE_LOADING_SET, false);
      NProgress.done();
    }
  },
  async [localTypes.FETCH_DOCTOR_ALL_SLOTS](_, doctorId) {
    NProgress.start();

    try {
      const { data: { slots } } = await api.get('Schedule', {
        params: { doctorId, timeZone: 3 },
      });

      return slots;
    } catch (e) {
      console.error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SCHEDULE_SINGLE_TEMPLATE_FETCH](ctx, templateId) {
    NProgress.start();

    try {
      const { data: template } = await api.get(`Schedule/GetSlotsTemplate/${templateId}`);

      return template;
    } catch (e) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить шаблон',
      });

      console.warn(e);

      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SCHEDULE_TEMPLATES_FETCH]({ commit }, staffId) {
    NProgress.start();

    try {
      commit(localTypes.SCHEDULE_TEMPLATES_LOADING_SET, true);

      const { data: { data: templates } } = await api.get(`v3/schedule/slot-template/list?staffId=${staffId}`);

      commit(localTypes.SCHEDULE_TEMPLATES_SET, templates);
    } catch (e) {
      commit(localTypes.SCHEDULE_TEMPLATES_SET, []);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить шаблоны',
      });

      console.warn(e);

      throw e;
    } finally {
      commit(localTypes.SCHEDULE_TEMPLATES_LOADING_SET, false);
      NProgress.done();
    }
  },
  async [localTypes.SCHEDULE_TEMPLATE_UPDATE]({ state, dispatch }, template) {
    NProgress.start();

    try {
      await api.post('v3/schedule/slot-template/update', template);

      showMessage({
        type: 'success',
        message: 'Изменения успешно применены',
      });

      dispatch(localTypes.SCHEDULE_FETCH, {
        id: state.selectedDoctor.id,
        date: state.selectedDate || new Date().toISOString().split('T')[0],
      });

      dispatch(localTypes.SCHEDULE_TEMPLATES_FETCH, state.selectedDoctor.mainDoctorSpecialization.staffId);
    } catch (e) {
      showMessage({
        title: 'Ошибка',
        type: 'error',
        message: 'Не удалось применить изменения',
      });

      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SCHEDULE_TEMPLATE_CREATE]({ state, dispatch }, template) {
    NProgress.start();

    try {
      await api.post('v3/schedule/slot-template/create', template);

      showMessage({
        type: 'success',
        message: 'Шаблон успешно создан',
      });

      dispatch(localTypes.SCHEDULE_FETCH, {
        id: state.selectedDoctor.id,
        date: state.selectedDate || new Date().toISOString().split('T')[0],
      });

      dispatch(localTypes.SCHEDULE_TEMPLATES_FETCH, state.selectedDoctor.mainDoctorSpecialization.staffId);
    } catch (e) {
      showMessage({
        title: 'Ошибка',
        type: 'error',
        message: 'Не удалось создать шаблон',
      });

      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SCHEDULE_TEMPLATE_DELETE]({ dispatch, state }, id) {
    NProgress.start();

    try {
      await api.post(`v3/schedule/slot-template/delete?slotTemplateId=${id}`);

      showMessage({
        type: 'success',
        message: 'Шаблон успешно удален',
      });

      dispatch(localTypes.SCHEDULE_FETCH, {
        id: state.selectedDoctor.id,
        date: state.selectedDate || new Date().toISOString().split('T')[0],
      });

      dispatch(localTypes.SCHEDULE_TEMPLATES_FETCH, state.selectedDoctor.mainDoctorSpecialization.staffId);
    } catch (e) {
      showMessage({
        title: 'Ошибка',
        type: 'error',
        message: 'Не удалось удалить шаблон',
      });

      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SCHEDULE_TEMPLATE_TOGGLE_ACTIVATION]({ state, dispatch }, { templateId, activationState }) {
    NProgress.start();

    try {
      await api.post(`v3/schedule/slot-template/${activationState ? 'activate' : 'deactivate'}?slotTemplateId=${templateId}`);

      showMessage({
        type: 'success',
        message: 'Состояние шаблона успешно изменено',
      });

      dispatch(localTypes.SCHEDULE_FETCH, {
        id: state.selectedDoctor.id,
        date: state.selectedDate || new Date().toISOString().split('T')[0],
      });

      dispatch(localTypes.SCHEDULE_TEMPLATES_FETCH, state.selectedDoctor.mainDoctorSpecialization.staffId);
    } catch (e) {
      showMessage({
        title: 'Ошибка',
        type: 'error',
        message: 'Не удалось изменить состояние шаблона',
      });

      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SCHEDULE_TEMPLATES_DEACTIVATE_ALL]({ state, dispatch, commit }, doctorId) {
    NProgress.start();

    try {
      commit(localTypes.SCHEDULE_TEMPLATES_LOADING_SET, true);

      await api.get(`Schedule/DeactivateSlotsTemplatesByDoctor/${doctorId}`);

      showMessage({
        type: 'success',
        message: 'Шаблоны успешно деактивированы',
      });

      dispatch(localTypes.SCHEDULE_FETCH, {
        id: state.selectedDoctor.id,
        date: state.selectedDate || new Date().toISOString().split('T')[0],
      });

      dispatch(localTypes.SCHEDULE_TEMPLATES_FETCH, state.selectedDoctor.id);
    } catch (e) {
      showMessage({
        title: 'Ошибка',
        type: 'error',
        message: 'Не удалось изменить состояние шаблона',
      });

      commit(localTypes.SCHEDULE_TEMPLATES_LOADING_SET, false);

      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SCHEDULE_CREATE_CHECKUP]({
    state, dispatch, rootState,
  }, checkup) {
    NProgress.start();

    try {
      const { data: { requestId: consultationId } } = await axios.post(
        `${APP_DOCTIS}Consultation/CreateWithUser`,
        checkup,
        {
          headers: {
            ...headers(),
            ApiKey: APP_DOCTIS_API_KEY,
            CrmProfileId: typeof rootState.Auth.user.userId === 'object' ? null : (rootState.Auth.user.userId || null),
            ClientType: 4,
          },
        },
      );

      showMessage({
        type: 'success',
        message: 'Пациент успешно записан',
      });

      if (state.selectedDoctor) {
        dispatch(localTypes.SCHEDULE_FETCH, {
          id: state.selectedDoctor.id,
          date: state.selectedDate || new Date().toISOString().split('T')[0],
        });
      }

      if (rootState.SchedulePage.doctors.find((doctor) => doctor.id === checkup.consultation.doctorId)) {
        dispatch(types.SCHEDULE_PAGE_SCHEDULE_FETCH_BY_DOCTORS, [checkup.consultation.doctorId], { root: true });
      }

      return consultationId;
    } catch (e) {
      showMessage({
        title: 'Ошибка',
        type: 'error',
        message: `Не удалось записать пациента. ${e?.response?.data?.message}`,
      });

      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DUTY_APPOINTMENT_CREATE](_, checkup) {
    NProgress.start();

    try {
      const { data: { requestId: consultationId } } = await api.post(`v2/consultations/dutyDoctor/${checkup.queue}/consultation`, checkup);

      showMessage({
        type: 'success',
        message: 'Пациент успешно записан',
      });
      return consultationId;
    } catch (e) {
      showMessage({
        title: 'Ошибка',
        type: 'error',
        message: `Не удалось записать пациента. ${e?.response?.data?.Message}`,
      });

      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SCHEDULE_CHECKUP_ADD_DOCUMENTS](_, { consultationId, fileToken, documentsId }) {
    NProgress.start();

    try {
      // const promiseArray = documentsId.map((id) => api.post(`v2/consultations/file?consultationId=${consultationId}&fileId=${id}`));
      // await Promise.all(promiseArray);

      const params = {
        headers: {
          ClientType: 4,
          ApiKey: APP_DOCTIS_API_KEY,
          ...headers(),
        },
      };
      const body = {
        fileToken,
        fileNames: documentsId,
      };

      await axios.post(`${APP_DOCTIS}consultation/${consultationId}/attach-files`, body, params);
    } catch (e) {
      showMessage({
        title: 'Ошибка',
        type: 'error',
        message: 'Не удалось привязать файлы к консультации',
      });

      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SCHEDULE_PATIENTS_SEARCH](_, {
    PhoneNumber,
    SearchFIO,
    PolicyNumber,
    PatientType,
    PatientId,
  }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      if (PhoneNumber) params.append('PhoneNumber', PhoneNumber);
      if (SearchFIO) params.append('SearchFIO', SearchFIO);
      if (PolicyNumber) params.append('PolicyNumber', PolicyNumber);
      if (PolicyNumber) params.append('PolicyNumber', PolicyNumber);
      if (PatientId) params.append('PatientId', PatientId);
      params.append('PatientType', PatientType);

      const { data: patients } = await api.get('v2/person/searchPatient', { params });
      return patients;
    } catch (e) {
      showMessage({
        title: 'Ошибка',
        type: 'error',
        message: 'Непредвиденная ошибка',
      });

      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SCHEDULE_FILETOKEN_FETCH](_, consultationId) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      params.append('consultationId', consultationId);

      const { data: fileToken } = await api.get('v2/person/fileToken', { params });
      return fileToken;
    } catch (e) {
      showMessage({
        title: 'Ошибка',
        type: 'error',
        message: 'Непредвиденная ошибка',
      });

      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DUTY_QUEUE_LIST_FETCH]() {
    NProgress.start();

    try {
      const { data } = await api.get('v2/DutyQueue/list');
      return data;
    } catch (e) {
      showMessage({
        title: 'Ошибка',
        type: 'error',
        message: 'Не удалось получить список очередей',
      });

      throw e;
    } finally {
      NProgress.done();
    }
  },
};
