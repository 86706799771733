var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-date-picker',{class:{ ['crm-fluid']: _vm.fluid },attrs:{"min-date":_vm.minDate,"max-date":_vm.maxDate,"value":_vm.value,"popover":{
    visibility: _vm.disabled ? 'hidden' : 'click',
    placement: _vm.placement,
    positionFixed: _vm.positionFixed,
  }},on:{"input":function($event){return _vm.$emit('change', $event)}},scopedSlots:_vm._u([{key:"default",fn:function({ inputProps, inputEvents, hidePopover }){return [_c('div',{staticClass:"date-picker",attrs:{"disabled":_vm.disabled}},[_c('input',_vm._g(_vm._b({staticClass:"crm-input",class:{
          'with-clear-button': _vm.showClearButton,
          error: _vm.error,
        },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return hidePopover.apply(null, arguments)}}},'input',inputProps,false),inputEvents)),(_vm.showClearButton)?_c('icon-close',{staticClass:"crm-close-icon",on:{"click":function($event){return _vm.onClickClose(hidePopover)}}}):_vm._e(),_c('div',{staticClass:"crm-icon-wrapper",class:{
          error: _vm.error,
        }},[_c('icon-calendar')],1),(_vm.error && _vm.errors.length)?[_c('div',{staticClass:"errors"},[_c('span',{staticClass:"crm-error-text"},[_vm._v(" "+_vm._s(_vm.errors[0])+" ")])])]:_vm._e()],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }