import AgreeModal from '@/components/common/modals/AgreeModal';

import AttachPatientModal from '@/components/FamilyPolicies/Modals/AttachPatientModal';
import StatusRequestModal from '@/components/Prices/Modals/StatusRequestModal';

import EditingServicesModal from '@/components/GluingServices/Modals/EditingServicesModal';
import GluePartnerServiceModal from '@/components/LaboratoryBindService/Modals/GluePartnerServiceModal';
import GlueOurServiceModal from '@/components/LaboratoryBindService/Modals/GlueOurServiceModal';
import PromocodeEditModalNew from '@/components/Promocodes/Modals/PromocodeEditModalNew';
import SpecializationEditModal from '@/components/Specializations/Modals/SpecializationEditModal';
import SpecializationReestablishModal from '@/components/Specializations/Modals/SpecializationReestablishModal';
import SpecializationDoctorsModal from '@/components/Specializations/Modals/SpecializationDoctorsModal';
import SpecializationDeleteModal from '@/components/Specializations/Modals/SpecializationDeleteModal';
import DownloadExcelModal from '@/components/Statistics/Modals/DownloadExcelModal';

import DoctorConsultationsInfoModal from '@/components/Doctors/Modals/DoctorConsultationsInfoModal';
import DoctorUnloadModal from '@/components/Doctors/Modals/DoctorUnloadModal';
import EditLaboratoryServiceModal from '@/components/Laboratories/Modals/Services/EditLaboratoryServiceModal';
import IndemnityPriceTemplateAddModal from '@/components/Indemnity/Modals/IndemnityPriceTemplateAddModal';
import IndemnityPriceTemplateEditModal from '@/components/Indemnity/Modals/IndemnityPriceTemplateEditModal';
import IndemnityPriceTemplateDeleteModal from '@/components/Indemnity/Modals/IndemnityPriceTemplateDeleteModal';
import IndemnityPriceClinicTemplateAddModal from '@/components/Indemnity/Modals/IndemnityPriceClinicTemplateAddModal';
import IndemnityPriceClinicTemplateEditModal from '@/components/Indemnity/Modals/IndemnityPriceClinicTemplateEditModal';
import IndemnityPriceClinicTemplateDeleteModal from '@/components/Indemnity/Modals/IndemnityPriceClinicTemplateDeleteModal';
import IndemnityLetterDeleteModal from '@/components/Indemnity/Modals/IndemnityLetterDeleteModal';
import IndemnityLetterAnnulmentModal from '@/components/Indemnity/Modals/IndemnityLetterAnnulmentModal';
import IndemnityHistoryModal from '@/components/Indemnity/Modals/IndemnityHistoryModal';
import ConsultationHistoryModal from '@/components/Consultations/Modals/ConsultationHistoryModal';
import HistoryConsultationReopeningModal from '@/components/Consultations/Modals/HistoryConsultationReopeningModal';
import ConsultationTransferModal from '@/components/Consultations/Modals/ConsultationTransferModal';
import ConsultationDeletingModal from '@/components/Consultations/Modals/ConsultationDeletingModal';
import ConsultationReopeningModal from '@/components/Consultations/Modals/ConsultationReopeningModal';
import EditBannerModal from '@/components/Settings/BannerManagement/Modals/EditBannerModal';
import BannerPreviewModal from '@/components/Settings/BannerManagement/Modals/BannerPreviewModal';
import ConsultationCancelModal from '@/components/Consultations/Modals/ConsultationCancelModal';
import ScheduleMakeAnAppointmentModal from '@/components/Schedule/Modals/ScheduleMakeAnAppointmentModal/index';
import ScheduleTemplateEditModal from '@/components/Schedule/Modals/ScheduleTemplateEditModal';
import ScheduleTemplatesModal from '@/components/Schedule/Modals/ScheduleTemplatesModal';
import ScheduleModal from '@/components/Schedule/Modals/SсheduleModal';
import ScheduleInformationModal from '@/components/Schedule/Modals/ScheduleInformationModal';
import ScheduleCancelModal from '@/components/Schedule/Modals/ScheduleCancelModal';
import LaboratoryPromocodeEditModalNew from '@/components/Laboratories/Modals/Promocodes/LaboratoryPromocodeEditModalNew';
import DeletePolicyModal from '@/components/PolicyList/Modal/DeletePolicyModal';
import DetachPolicyModal from '@/components/PolicyList/Modal/DetachPolicyModal';
import RegionEditModal from '@/components/Clinics/Regions/Modals/RegionEditModal';

import ScheduleTransferModal from '@/components/Schedule/Modals/ScheduleTransferModal';
import IndemnityModal from '@/components/Indemnity/Modals/IndemnityModal';
import IndemnityTemplateDeleteModal from '@/components/Indemnity/Modals/IndemnityTemplateDeleteModal';
import AppealEditorModal from '@/components/Appeals/Modals/AppealEditorModal';
import FileModal from '@/components/FileModal';
import ScheduleHistoryModal from '@/components/Schedule/Modals/ScheduleHistoryModal';

import PreparingAnalysisCreateModal from '@/components/Laboratories/Modals/PreparingAnalysis/PreparingAnalysisCreateModal';
import PreparingAnalysisDeleteModal from '@/components/Laboratories/Modals/PreparingAnalysis/PreparingAnalysisDeleteModal';
import PreparingAnalysisEditModal from '@/components/Laboratories/Modals/PreparingAnalysis/PreparingAnalysisEditModal';

import UserViewModal from '../../../components/Users/Modals/UserViewModal';
import UserDeleteModal from '../../../components/Users/Modals/UserDeleteModal';
import UserClinicDeleteModal from '../../../components/Users/Modals/UserClinicDeleteModal';
import PolicyViewModal from '../../../components/PolicyList/PolicyViewModal';
import InsurancePolicyExport from '../../../components/InsurancePrograms/Modals/InsurancePolicyExport';
import InsuranceCompanyPhotoModal from '../../../components/InsurancePrograms/Modals/InsuranceCompanyPhotoModal';
import InsuranceImportModal from '../../../components/InsurancePrograms/Modals/InsuranceImportModal';
import InsuranceProgramDeactivateModal from '../../../components/InsurancePrograms/Modals/InsuranceProgramDeactivateModal';
import InsuranceProgramReestrImportModal from '../../../components/InsurancePrograms/Modals/InsuranceProgramReestrImportModal';
import CityCreateModal from '../../../components/Clinics/Modals/CityCreateModal';
import ClinicInfoCardDeleteModal from '../../../components/Clinics/Modals/ClinicInfoCardDeleteModal';
import ClinicsModal from '../../../components/Clinics/Modals/ClinicsModal';
import ClinicPageModal from '../../../components/Clinics/Modals/ClinicPageModal';
import YandexMapModal from '../../../components/Clinics/Modals/YandexMapModal';
import ClinicPackageModal from '../../../components/Clinics/Modals/ClinicPackageModal';
import ClinicResponsibleModal from '../../../components/Clinics/Modals/ClinicResponsibleModal';
import ClinicResponsibleClinicsModal from '../../../components/Clinics/Modals/ClinicResponsibleClinicsModal';
import ClinicEditModal from '../../../components/Clinics/Modals/ClinicEditModal';
import ConsultationControlModal from '../../../components/Consultations/Modals/ConsultationControlModal';
import ConsultationControlExportModal from '../../../components/Consultations/Modals/ConsultationControlExportModal';
import CheckupExportModal from '../../../components/Statistics/Modals/CheckupExportModal';

import AddClinicDownloadFileModal from '../../../components/Clinics/Modals/AddClinicDownloadFileModal';
import FileModalModernNew from '../../../components/FileModalModernNew.vue';
import EditClinicDocument from '../../../components/Clinics/Modals/EditClinicDocument';
import AddClinicDocument from '../../../components/Clinics/Modals/AddClinicDocument';
import DoctorsPinModal from '../../../components/Clinics/Modals/DoctorsPinModal';
import AttachPolicyModal from '../../../components/Patients/Modals/AttachPolicyModal';
import PolicyHasIndemnityModal from '../../../components/Patients/Modals/PolicyHasIndemnityModal';
import PolicyLimitsModal from '../../../components/Patients/Modals/PolicyLimitsModal';
import PatientAppealsModal from '../../../components/Patients/Modals/PatientAppealsModal';
import PatientDetailModal from '../../../components/Patients/Modals/PatientDetailModal';
import PatientDeviceModal from '../../../components/Patients/Modals/PatientDeviceModal';
import PatientDeviceDeleteModal from '../../../components/Patients/Modals/PatientDeviceDeleteModal';
import PatientDeleteModal from '../../../components/Patients/Modals/PatientDeleteModal';
import PatientDeleteDopModal from '../../../components/Patients/Modals/PatientDeleteDopModal';
import PatientSetMainUserModal from '../../../components/Patients/Modals/PatientSetMainUserModal';
import PatientDepersonalizateModal from '../../../components/Patients/Modals/PatientDepersonalizateModal';
import PatientConsultationModal from '../../../components/Patients/Modals/PatientConsultationModal';
import PatientInfoModal from '../../../components/Patients/Modals/PatientInfoModal';
import PatientPoliciesModal from '../../../components/Patients/Modals/PatientPoliciesModal';
import PatientServicesModal from '../../../components/Patients/Modals/PatientServicesModal';
import PatientReestablishModal from '../../../components/Patients/Modals/PatientReestablishModal';
import PolicyDetachModal from '../../../components/Patients/Modals/PolicyDetachModal';
import PolicyDetachDopModal from '../../../components/Patients/Modals/PolicyDetachDopModal';
import PolicyActivateModal from '../../../components/Patients/Modals/PolicyActivateModal';
import DoctorsModal from '../../../components/Doctors/Modals/DoctorsModal';
import DoctorReestablishModal from '../../../components/Doctors/Modals/DoctorReestablishModal';
import DoctorDeviceModal from '../../../components/Doctors/Modals/DoctorDeviceModal';
import DoctorDeviceDeleteModal from '../../../components/Doctors/Modals/DoctorDeviceDeleteModal';
import DoctorAvatarAddModal from '../../../components/Doctors/Modals/DoctorAvatarAddModal';
import DoctorAvatarDeleteModal from '../../../components/Doctors/Modals/DoctorAvatarDeleteModal';
import DoctorEvaluationModal from '../../../components/Doctors/Modals/DoctorEvaluationModal';
import DoctorInfoModal from '../../../components/Doctors/Modals/DoctorInfoModal';
import ClinicRecoveryModal from '../../../components/Clinics/Modals/ClinicRecoveryModal';
import PricesHistoryModal from '../../../components/Prices/PricesHistoryModal';
import PriceHistoryModal from '../../../components/Prices/PriceHistoryModal';
import PricesImportInClinic from '../../../components/Prices/PricesImportInClinic';
import PricesListViewModal from '../../../components/Prices/PricesListViewModal';
import LaboratoryBranchesModal from '../../../components/Laboratories/Modals/LaboratoryBranchesModal';
import AddPartnerModal from '../../../components/Laboratories/Modals/Partners/AddPartnerModal';
import AddBranchImportModal from '../../../components/Laboratories/Modals/Branches/AddBranchImportModal';
import EditPartnerModal from '../../../components/Laboratories/Modals/Partners/EditPartnerModal';
import AddLegalPartnerModal from '../../../components/Laboratories/Modals/LegalPartners/AddLegalPartnerModal';
import EditLegalPartnerModal from '../../../components/Laboratories/Modals/LegalPartners/EditLegalPartnerModal';
import LegalEntityReestrExportModal from '../../../components/LegalEntities/Modals/LegalEntityReestrExportModal';
import LegalEntityReestrImportModal from '../../../components/LegalEntities/Modals/LegalEntityReestrImportModal';
import RoleEditModal from '../../../components/Roles/Modals/RoleEditModal';
import PageEditModal from '../../../components/Pages/Modals/PageEditModal';

import ImportPartnerService from '../../../components/Laboratories/Modals/PartnerService/ImportPartnerService';
import ImportServices from '../../../components/Laboratories/Modals/Services/ImportServices';
import OrdersHistoryModal from '../../../components/Laboratories/Modals/Orders/OrdersHistoryModal';
import UploadOrderAnalysis from '../../../components/Laboratories/Modals/Orders/UploadOrderAnalysis';
import AddPartnerPrices from '../../../components/Laboratories/Modals/Partners/AddPartnerPrices';
import InsuranceSubprogramUploadFile from '../../../components/InsurancePrograms/Modals/InsuranceSubprogramUploadFile';
import PartnerServiceMap from '../../../components/Laboratories/Modals/PartnerService/PartnerServiceMap';
import AddBranchModal from '../../../components/Laboratories/Modals/Branches/AddBranchModal';
import ImportBranches from '../../../components/Laboratories/Modals/Branches/ImportBranches';
import EditBranchModal from '../../../components/Laboratories/Modals/Branches/EditBranchModal';
import AddServiceModal from '../../../components/Laboratories/Modals/Services/AddServiceModal';
import ServicePricesModal from '../../../components/Laboratories/Modals/Services/ServicePricesModal';
import CityEditModal from '../../../components/Laboratories/Modals/Cities/CityEditModal';
import DeleteCityModal from '../../../components/Laboratories/Modals/Cities/DeleteCityModal';
import AddTagModal from '../../../components/Laboratories/Modals/Tags/AddTagModal';
import EditTagModal from '../../../components/Laboratories/Modals/Tags/EditTagModal';
import QueueDeleteModal from '../../../components/Queues/Modals/QueueDeleteModal';
import QueueConsultationDeleteModal from '../../../components/Queues/Modals/QueueConsultationDeleteModal';
import QueuesTagDeleteModal from '../../../components/Queues/Modals/QueuesTagDeleteModal';
import QueuesTagEditModal from '../../../components/Queues/Modals/QueuesTagEditModal';
import QueueEditModal from '../../../components/Queues/Modals/QueueEditModal';
import SmsSendModal from '../../../components/Sms/Modals/SmsSendModal';
import SmsSendingConfirmation from '../../../components/Sms/Modals/SmsSendingConfirmation';
import SmsSending from '../../../components/Sms/Modals/SmsSending';
import SmsTemplateEditModal from '../../../components/Sms/Modals/SmsTemplateEditModal';
import SmsTemplateDeleteModal from '../../../components/Sms/Modals/SmsTemplateDeleteModal';
import ReferralDutyConsultationImportModal from '../../../components/Consultations/Modals/ReferralDutyConsultationImportModal';

export default [
  {
    name: 'AgreeModal',
    Component: AgreeModal,
  },
  {
    name: 'UserViewModal',
    Component: UserViewModal,
  },
  {
    name: 'StatusRequestModal',
    Component: StatusRequestModal,
  },
  {
    name: 'EditingServicesModal',
    Component: EditingServicesModal,
  },
  {
    name: 'GluePartnerServiceModal',
    Component: GluePartnerServiceModal,
  },
  {
    name: 'GlueOurServiceModal',
    Component: GlueOurServiceModal,
  },
  {
    name: 'UserDeleteModal',
    Component: UserDeleteModal,
  },
  {
    name: 'UserClinicDeleteModal',
    Component: UserClinicDeleteModal,
  },
  {
    name: 'PolicyViewModal',
    Component: PolicyViewModal,
  },
  {
    name: 'InsurancePolicyExport',
    Component: InsurancePolicyExport,
  },
  {
    name: 'InsuranceCompanyPhotoModal',
    Component: InsuranceCompanyPhotoModal,
  },
  {
    name: 'InsuranceImportModal',
    Component: InsuranceImportModal,
  },
  {
    name: 'InsuranceProgramDeactivateModal',
    Component: InsuranceProgramDeactivateModal,
  },
  {
    name: 'InsuranceProgramReestrImportModal',
    Component: InsuranceProgramReestrImportModal,
  },
  {
    name: 'DeletePolicyModal',
    Component: DeletePolicyModal,
  },
  {
    name: 'DetachPolicyModal',
    Component: DetachPolicyModal,
  },
  {
    name: 'PolicyHasIndemnityModal',
    Component: PolicyHasIndemnityModal,
  },
  {
    name: 'PolicyLimitsModal',
    Component: PolicyLimitsModal,
  },
  {
    name: 'PromocodeEditModal',
    Component: PromocodeEditModalNew,
  },
  {
    name: 'LaboratoryPromocodeEditModal',
    Component: LaboratoryPromocodeEditModalNew,
  },
  {
    name: 'SpecializationEditModal',
    Component: SpecializationEditModal,
  },
  {
    name: 'SpecializationReestablishModal',
    Component: SpecializationReestablishModal,
  },
  {
    name: 'SpecializationDoctorsModal',
    Component: SpecializationDoctorsModal,
  },
  {
    name: 'SpecializationDeleteModal',
    Component: SpecializationDeleteModal,
  },
  {
    name: 'CityCreateModal',
    Component: CityCreateModal,
  },
  {
    name: 'ClinicInfoCardDeleteModal',
    Component: ClinicInfoCardDeleteModal,
  },
  {
    name: 'ClinicsModal',
    Component: ClinicsModal,
  },
  {
    name: 'YandexMapModal',
    Component: YandexMapModal,
  },
  {
    name: 'ClinicPackageModal',
    Component: ClinicPackageModal,
  },
  {
    name: 'ConsultationHistoryModal',
    Component: ConsultationHistoryModal,
  },
  {
    name: 'HistoryConsultationReopeningModal',
    Component: HistoryConsultationReopeningModal,
  },

  {
    name: 'ConsultationTransferModal',
    Component: ConsultationTransferModal,
  },
  {
    name: 'ConsultationDeletingModal',
    Component: ConsultationDeletingModal,
  },
  {
    name: 'ConsultationReopeningModal',
    Component: ConsultationReopeningModal,
  },
  {
    name: 'EditBannerModal',
    Component: EditBannerModal,
  },
  {
    name: 'BannerPreviewModal',
    Component: BannerPreviewModal,
  },
  {
    name: 'ConsultationCancelModal',
    Component: ConsultationCancelModal,
  },
  {
    name: 'ConsultationControlModal',
    Component: ConsultationControlModal,
  },
  {
    name: 'ConsultationControlExportModal',
    Component: ConsultationControlExportModal,
  },
  {
    name: 'CheckupExportModal',
    Component: CheckupExportModal,
  },
  {
    name: 'ScheduleMakeAnAppointmentModal',
    Component: ScheduleMakeAnAppointmentModal,
  },
  {
    name: 'ScheduleTemplateEditModal',
    Component: ScheduleTemplateEditModal,
  },
  {
    name: 'ScheduleTemplatesModal',
    Component: ScheduleTemplatesModal,
  },
  {
    name: 'ScheduleModal',
    Component: ScheduleModal,
  },
  {
    name: 'ScheduleHistoryModal',
    Component: ScheduleHistoryModal,
  },
  {
    name: 'PreparingAnalysisCreateModal',
    Component: PreparingAnalysisCreateModal,
  },
  {
    name: 'PreparingAnalysisDeleteModal',
    Component: PreparingAnalysisDeleteModal,
  },
  {
    name: 'PreparingAnalysisEditModal',
    Component: PreparingAnalysisEditModal,
  },
  {
    name: 'ScheduleInformationModal',
    Component: ScheduleInformationModal,
  },
  {
    name: 'ScheduleCancelModal',
    Component: ScheduleCancelModal,
  },
  {
    name: 'ScheduleTransferModal',
    Component: ScheduleTransferModal,
  },
  {
    name: 'DownloadExcelModal',
    Component: DownloadExcelModal,
  },
  {
    name: 'AttachPolicyModal',
    Component: AttachPolicyModal,
  },
  {
    name: 'PatientAppealsModal',
    Component: PatientAppealsModal,
  },
  {
    name: 'PatientDeviceModal',
    Component: PatientDeviceModal,
  },
  {
    name: 'PatientDetailModal',
    Component: PatientDetailModal,
  },

  {
    name: 'PatientDeviceDeleteModal',
    Component: PatientDeviceDeleteModal,
  },
  {
    name: 'PatientDeleteModal',
    Component: PatientDeleteModal,
  },
  {
    name: 'PatientDeleteDopModal',
    Component: PatientDeleteDopModal,
  },
  {
    name: 'PatientSetMainUserModal',
    Component: PatientSetMainUserModal,
  },
  {
    name: 'PolicyDetachModal',
    Component: PolicyDetachModal,
  },
  {
    name: 'PolicyDetachDopModal',
    Component: PolicyDetachDopModal,
  },
  {
    name: 'PolicyActivateModal',
    Component: PolicyActivateModal,
  },
  {
    name: 'PatientDepersonalizateModal',
    Component: PatientDepersonalizateModal,
  },
  {
    name: 'PatientDeviceDeleteModal',
    Component: PatientDeviceDeleteModal,
  },
  {
    name: 'PatientConsultationModal',
    Component: PatientConsultationModal,
  },
  {
    name: 'PatientInfoModal',
    Component: PatientInfoModal,
  },
  {
    name: 'PatientPoliciesModal',
    Component: PatientPoliciesModal,
  },
  {
    name: 'PatientServicesModal',
    Component: PatientServicesModal,
  },
  {
    name: 'PatientReestablishModal',
    Component: PatientReestablishModal,
  },
  {
    name: 'AttachPatientModal',
    Component: AttachPatientModal,
  },
  {
    name: 'DoctorDeviceModal',
    Component: DoctorDeviceModal,
  },
  {
    name: 'DoctorDeviceDeleteModal',
    Component: DoctorDeviceDeleteModal,
  },
  {
    name: 'DoctorAvatarAddModal',
    Component: DoctorAvatarAddModal,
  },
  {
    name: 'DoctorAvatarDeleteModal',
    Component: DoctorAvatarDeleteModal,
  },
  {
    name: 'DoctorEvaluationModal',
    Component: DoctorEvaluationModal,
  },
  {
    name: 'DoctorInfoModal',
    Component: DoctorInfoModal,
  },
  {
    name: 'DoctorConsultationsInfoModal',
    Component: DoctorConsultationsInfoModal,
  },
  {
    name: 'DoctorUnloadModal',
    Component: DoctorUnloadModal,
  },
  {
    name: 'AddClinicDownloadFileModal',
    Component: AddClinicDownloadFileModal,
  },
  {
    name: 'FileModalModernNew',
    Component: FileModalModernNew,
  },
  {
    name: 'EditClinicDocument',
    Component: EditClinicDocument,
  },
  {
    name: 'AddClinicDocument',
    Component: AddClinicDocument,
  },
  {
    name: 'DoctorsPinModal',
    Component: DoctorsPinModal,
  },
  {
    name: 'DoctorsModal',
    Component: DoctorsModal,
  },
  {
    name: 'DoctorReestablishModal',
    Component: DoctorReestablishModal,
  },
  {
    name: 'ClinicResponsibleModal',
    Component: ClinicResponsibleModal,
  },
  {
    name: 'ClinicResponsibleClinicsModal',
    Component: ClinicResponsibleClinicsModal,
  },
  {
    name: 'ClinicEditModal',
    Component: ClinicEditModal,
  },
  {
    name: 'ClinicPageModal',
    Component: ClinicPageModal,
  },
  {
    name: 'ClinicRecoveryModal',
    Component: ClinicRecoveryModal,
  },
  {
    name: 'PricesHistoryModal',
    Component: PricesHistoryModal,
  },
  {
    name: 'PriceHistoryModal',
    Component: PriceHistoryModal,
  },
  {
    name: 'PricesImportInClinic',
    Component: PricesImportInClinic,
  },
  {
    name: 'PricesListViewModal',
    Component: PricesListViewModal,
  },
  {
    name: 'LaboratoryBranchesModal',
    Component: LaboratoryBranchesModal,
  },
  {
    name: 'AddPartnerModal',
    Component: AddPartnerModal,
  },
  {
    name: 'EditPartnerModal',
    Component: EditPartnerModal,
  },
  {
    name: 'AddTagModal',
    Component: AddTagModal,
  },
  {
    name: 'EditTagModal',
    Component: EditTagModal,
  },

  {
    name: 'AddLegalPartnerModal',
    Component: AddLegalPartnerModal,
  },
  {
    name: 'EditLegalPartnerModal',
    Component: EditLegalPartnerModal,
  },
  {
    name: 'LegalEntityReestrExportModal',
    Component: LegalEntityReestrExportModal,
  },
  {
    name: 'LegalEntityReestrImportModal',
    Component: LegalEntityReestrImportModal,
  },
  {
    name: 'RoleEditModal',
    Component: RoleEditModal,
  },
  {
    name: 'PageEditModal',
    Component: PageEditModal,
  },

  {
    name: 'ImportPartnerService',
    Component: ImportPartnerService,
  },
  {
    name: 'ImportServices',
    Component: ImportServices,
  },
  {
    name: 'OrdersHistoryModal',
    Component: OrdersHistoryModal,
  },
  {
    name: 'UploadOrderAnalysis',
    Component: UploadOrderAnalysis,
  },
  {
    name: 'AddPartnerPrices',
    Component: AddPartnerPrices,
  },
  {
    name: 'AddBranchImportModal',
    Component: AddBranchImportModal,
  },
  {
    name: 'PartnerServiceMap',
    Component: PartnerServiceMap,
  },
  {
    name: 'AddBranchModal',
    Component: AddBranchModal,
  },
  {
    name: 'EditBranchModal',
    Component: EditBranchModal,
  },
  {
    name: 'AddServiceModal',
    Component: AddServiceModal,
  },
  {
    name: 'ServicePricesModal',
    Component: ServicePricesModal,
  },
  {
    name: 'EditLaboratoryServiceModal',
    Component: EditLaboratoryServiceModal,
  },
  {
    name: 'CityEditModal',
    Component: CityEditModal,
  },
  {
    name: 'DeleteCityModal',
    Component: DeleteCityModal,
  },
  {
    name: 'ImportBranches',
    Component: ImportBranches,
  },
  {
    name: 'QueueDeleteModal',
    Component: QueueDeleteModal,
  },
  {
    name: 'QueuesTagDeleteModal',
    Component: QueuesTagDeleteModal,
  },
  {
    name: 'QueuesTagEditModal',
    Component: QueuesTagEditModal,
  },
  {
    name: 'QueueEditModal',
    Component: QueueEditModal,
  },
  {
    name: 'SmsSendModal',
    Component: SmsSendModal,
  },
  {
    name: 'SmsSending',
    Component: SmsSending,
  },
  {
    name: 'SmsSendingConfirmation',
    Component: SmsSendingConfirmation,
  },
  {
    name: 'SmsTemplateEditModal',
    Component: SmsTemplateEditModal,
  },
  {
    name: 'SmsTemplateDeleteModal',
    Component: SmsTemplateDeleteModal,
  },

  {
    name: 'InsuranceSubprogramUploadFile',
    Component: InsuranceSubprogramUploadFile,
  },

  {
    name: 'IndemnityPriceTemplateAddModal',
    Component: IndemnityPriceTemplateAddModal,
  },
  {
    name: 'IndemnityPriceTemplateEditModal',
    Component: IndemnityPriceTemplateEditModal,
  },
  {
    name: 'IndemnityPriceTemplateDeleteModal',
    Component: IndemnityPriceTemplateDeleteModal,
  },
  {
    name: 'IndemnityPriceClinicTemplateAddModal',
    Component: IndemnityPriceClinicTemplateAddModal,
  },
  {
    name: 'IndemnityPriceClinicTemplateEditModal',
    Component: IndemnityPriceClinicTemplateEditModal,
  },
  {
    name: 'IndemnityPriceClinicTemplateDeleteModal',
    Component: IndemnityPriceClinicTemplateDeleteModal,
  },
  {
    name: 'IndemnityLetterDeleteModal',
    Component: IndemnityLetterDeleteModal,
  },
  {
    name: 'IndemnityLetterAnnulmentModal',
    Component: IndemnityLetterAnnulmentModal,
  },
  {
    name: 'IndemnityHistoryModal',
    Component: IndemnityHistoryModal,
  },
  {
    name: 'RegionEditModal',
    Component: RegionEditModal,
  },
  {
    name: 'QueueConsultationDeleteModal',
    Component: QueueConsultationDeleteModal,
  },
  {
    name: 'IndemnityModal',
    Component: IndemnityModal,
  },
  {
    name: 'IndemnityTemplateDeleteModal',
    Component: IndemnityTemplateDeleteModal,
  },

  {
    name: 'AppealEditorModal',
    Component: AppealEditorModal,
  },
  {
    name: 'FileModal',
    Component: FileModal,
  },
  {
    name: 'ReferralDutyConsultationImportModal',
    Component: ReferralDutyConsultationImportModal,
  },
];
