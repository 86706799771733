<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    title="Обращения"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <PatientDetailAppeals
        :patient-id="patient.id"
        :patient="patient"
        class="patient-appeals pt-1"
      />
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';
import PatientDetailAppeals from '@/components/Patients/PatientDetailAppeals';

export default {
  name: 'PatientDetailAppealsModal',
  components: {
    Preloader,
    PatientDetailAppeals,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    patientId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      patient: null,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      await this.getPatient();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async getPatient() {
      const patient = await this.$store.dispatch(this.$types.PATIENT_FETCH, this.patientId);
      const deviceId = await this.$store.dispatch(this.$types.GET_USER_DEVICE_ID, this.patientId);
      this.patient = { ...patient, deviceId };
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-appeals {
  min-height: 50vh;
}

</style>
